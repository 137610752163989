/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum ActiveRankType {
  Main = "Main",
  Sub = "Sub",
}

export enum ActivityType {
  Crossfit = "Crossfit",
  Golf = "Golf",
  Hike = "Hike",
  IceSkate = "IceSkate",
  InlineSkate = "InlineSkate",
  Ride = "Ride",
  RockClimbing = "RockClimbing",
  Run = "Run",
  Swim = "Swim",
  Velomobile = "Velomobile",
  VirtualRide = "VirtualRide",
  VirtualRun = "VirtualRun",
  Walk = "Walk",
  Workout = "Workout",
  Yog = "Yog",
}

export enum ApplicationStatus {
  Archived = "Archived",
  Hired = "Hired",
  InterviewScheduled = "InterviewScheduled",
  NotSuitable = "NotSuitable",
  Progressing = "Progressing",
  Submitted = "Submitted",
  WaitingResponse = "WaitingResponse",
  WaitingSignedContract = "WaitingSignedContract",
}

export enum CashDepositType {
  Adjustment = "Adjustment",
  HQPayment = "HQPayment",
}

export enum ContractType {
  Casual = "Casual",
  FullTime = "FullTime",
  PartTime = "PartTime",
}

export enum DispatchType {
  Permanent = "Permanent",
  Temporary = "Temporary",
}

export enum EntityType {
  ActiveRank = "ActiveRank",
  Applicant = "Applicant",
  Branch = "Branch",
  CashDeposit = "CashDeposit",
  Category = "Category",
  Comment = "Comment",
  Contact = "Contact",
  Contract = "Contract",
  Credential = "Credential",
  FCPForm = "FCPForm",
  FCPItem = "FCPItem",
  FCPTask = "FCPTask",
  File = "File",
  FullHouse = "FullHouse",
  FullHouseSet = "FullHouseSet",
  HCode = "HCode",
  JobDescription = "JobDescription",
  LearningCertificate = "LearningCertificate",
  License = "License",
  MonthlySummary = "MonthlySummary",
  Note = "Note",
  Operation = "Operation",
  OperationConfig = "OperationConfig",
  OrderItem = "OrderItem",
  PayRate = "PayRate",
  PayrunBlock = "PayrunBlock",
  PayrunNote = "PayrunNote",
  PayrunSummary = "PayrunSummary",
  Position = "Position",
  Product = "Product",
  PublicHoliday = "PublicHoliday",
  PurchaseOrder = "PurchaseOrder",
  PurchaseOrderItem = "PurchaseOrderItem",
  Rank = "Rank",
  Review = "Review",
  SMR = "SMR",
  Shift = "Shift",
  StandingOrder = "StandingOrder",
  Store = "Store",
  Supplier = "Supplier",
  TimeOff = "TimeOff",
  Timesheet = "Timesheet",
  User = "User",
  UserContract = "UserContract",
  Vaccine = "Vaccine",
  Visa = "Visa",
}

export enum FCPFrequency {
  Daily = "Daily",
  Hourly = "Hourly",
  Monthly = "Monthly",
  OnEvent = "OnEvent",
  Quarterly = "Quarterly",
  SixMonthly = "SixMonthly",
  TwoHourly = "TwoHourly",
  TwoMonthly = "TwoMonthly",
  TwoWeekly = "TwoWeekly",
  Weekly = "Weekly",
  Yearly = "Yearly",
}

export enum FCPTaskType {
  Issued = "Issued",
  None = "None",
  Resolved = "Resolved",
}

export enum FORM {
  STORE_ALLERGENS = "STORE_ALLERGENS",
  STORE_APPROVED_SUPPLIERS = "STORE_APPROVED_SUPPLIERS",
  STORE_CALIBRATING_THERMOMETER = "STORE_CALIBRATING_THERMOMETER",
  STORE_CHECKING_PLANS_WOKRING_WELL = "STORE_CHECKING_PLANS_WOKRING_WELL",
  STORE_CHEMICAL_LIST = "STORE_CHEMICAL_LIST",
  STORE_CHIKCEN_TEMPERATURE = "STORE_CHIKCEN_TEMPERATURE",
  STORE_CLEANING_SCHEDULE = "STORE_CLEANING_SCHEDULE",
  STORE_COOLING_COOKED_FOOD = "STORE_COOLING_COOKED_FOOD",
  STORE_CUSTOMER_COMPLAINT = "STORE_CUSTOMER_COMPLAINT",
  STORE_FRIDGE_TEMPERATURE = "STORE_FRIDGE_TEMPERATURE",
  STORE_HOT_FOOD_TEMPERATURE = "STORE_HOT_FOOD_TEMPERATURE",
  STORE_MAINTENANCE_RECORD = "STORE_MAINTENANCE_RECORD",
  STORE_PH_COOKING_METHOD = "STORE_PH_COOKING_METHOD",
  STORE_POULTRY_COOKING_METHOD = "STORE_POULTRY_COOKING_METHOD",
  STORE_SELLING_FOOD_TO_OTHER_BUSINESS_DAILY = "STORE_SELLING_FOOD_TO_OTHER_BUSINESS_DAILY",
  STORE_SELLING_FOOD_TO_OTHER_BUSINESS_INITIAL = "STORE_SELLING_FOOD_TO_OTHER_BUSINESS_INITIAL",
  STORE_STAFF_SICKNESS = "STORE_STAFF_SICKNESS",
  STORE_SUPPLIER_DELIVERIES = "STORE_SUPPLIER_DELIVERIES",
  STORE_SUSHIRICE_PH_RECORD = "STORE_SUSHIRICE_PH_RECORD",
  STORE_TRANSPORTING_FOOD = "STORE_TRANSPORTING_FOOD",
  STORE_WHEN_SOMETHING_GOES_WRONG = "STORE_WHEN_SOMETHING_GOES_WRONG",
  USER_STAFF_TRAINING = "USER_STAFF_TRAINING",
}

export enum FileTypes {
  CashDepositDocument = "CashDepositDocument",
  Contract = "Contract",
  Cv = "Cv",
  DesignAsset = "DesignAsset",
  Identification = "Identification",
  Initial = "Initial",
  JobDescription = "JobDescription",
  LearningCertificate = "LearningCertificate",
  LicenseDocument = "LicenseDocument",
  Menu = "Menu",
  NoteAttachment = "NoteAttachment",
  PerformanceReport = "PerformanceReport",
  PersonalDocument = "PersonalDocument",
  Profile = "Profile",
  ProfilePicture = "ProfilePicture",
  ResignLetter = "ResignLetter",
  ReviewScreenshot = "ReviewScreenshot",
  SMR = "SMR",
  Signature = "Signature",
  SupplierContract = "SupplierContract",
  TaxForm = "TaxForm",
  Template = "Template",
  VisaCopy = "VisaCopy",
  VisaDocument = "VisaDocument",
}

export enum LicenseType {
  EmployerAccreditation = "EmployerAccreditation",
  FCP = "FCP",
  GeneralManager = "GeneralManager",
  JobCheck = "JobCheck",
  NoticeOfRegistration = "NoticeOfRegistration",
  OnLicense = "OnLicense",
}

export enum LogTypes {
  Approve = "Approve",
  Create = "Create",
  Delete = "Delete",
  Disapprove = "Disapprove",
  Edit = "Edit",
  Place = "Place",
}

export enum MenuType {
  Fresh = "Fresh",
  None = "None",
  PanFried = "PanFried",
  Tempura = "Tempura",
}

export enum NoteTypes {
  Employment = "Employment",
  FCP = "FCP",
  General = "General",
  OtherTraining = "OtherTraining",
  Performance = "Performance",
  WorkEthic = "WorkEthic",
  WorkSafe = "WorkSafe",
}

export enum OperationType {
  Banking = "Banking",
  COGs = "COGs",
  Card = "Card",
  Cash = "Cash",
  Delivery = "Delivery",
  Expenses = "Expenses",
  Sales = "Sales",
  Surcharge = "Surcharge",
  Tip = "Tip",
}

export enum POSType {
  LIGHTSPEED = "LIGHTSPEED",
  POSBOSS = "POSBOSS",
}

export enum PartDayTypes {
  FullDay = "FullDay",
  HalfDay = "HalfDay",
  OneQuaterDay = "OneQuaterDay",
  ThreeQuatersDay = "ThreeQuatersDay",
}

export enum Permission {
  Activity_Read = "Activity_Read",
  Activity_Write = "Activity_Write",
  Admin = "Admin",
  Branch_Assets_Read = "Branch_Assets_Read",
  Branch_Assets_Write = "Branch_Assets_Write",
  Branch_Read = "Branch_Read",
  Branch_Write = "Branch_Write",
  COGs_Read = "COGs_Read",
  COGs_Write = "COGs_Write",
  CashFlow_Read = "CashFlow_Read",
  Cash_Manage = "Cash_Manage",
  Contract_Read = "Contract_Read",
  Contract_Write = "Contract_Write",
  Credential_Read = "Credential_Read",
  Credential_Write = "Credential_Write",
  Expense_Read = "Expense_Read",
  Expense_Write = "Expense_Write",
  FCP_Read = "FCP_Read",
  FCP_Write = "FCP_Write",
  Licence_Read = "Licence_Read",
  Licence_Write = "Licence_Write",
  ManagementChannel_Access = "ManagementChannel_Access",
  Note_Read = "Note_Read",
  Note_Write = "Note_Write",
  OnSite_Work = "OnSite_Work",
  Order_Read = "Order_Read",
  Order_Write = "Order_Write",
  PayRate_Access = "PayRate_Access",
  Profile_Read = "Profile_Read",
  Profile_Write = "Profile_Write",
  ProfitAndLoss_Read = "ProfitAndLoss_Read",
  Report_Read = "Report_Read",
  Report_Write = "Report_Write",
  Sales_Read = "Sales_Read",
  Sales_Write = "Sales_Write",
  Schedule_Read = "Schedule_Read",
  Schedule_Timesheet_Manage = "Schedule_Timesheet_Manage",
  Schedule_Write = "Schedule_Write",
  Visa_Read = "Visa_Read",
  Visa_Write = "Visa_Write",
}

export enum ProductStatusTypes {
  Active = "Active",
  Disfavour = "Disfavour",
  Favour = "Favour",
  Fixed = "Fixed",
  Inactive = "Inactive",
}

export enum Regions {
  Auckland = "Auckland",
  Christchurch = "Christchurch",
  Dunedin = "Dunedin",
  Otago = "Otago",
  Queenstown = "Queenstown",
  Sydney = "Sydney",
}

export enum SMRStatus {
  AQUIRED = "AQUIRED",
  EXPIRED = "EXPIRED",
  JOB_LISTED = "JOB_LISTED",
}

export enum StandingOrderStatus {
  Active = "Active",
  Created = "Created",
  Deleted = "Deleted",
  Paused = "Paused",
  Stopped = "Stopped",
}

export enum SupplierTypes {
  Broadband = "Broadband",
  Cleaning = "Cleaning",
  Customer = "Customer",
  Delivery = "Delivery",
  Electricity = "Electricity",
  Fitout = "Fitout",
  Food = "Food",
  Gas = "Gas",
  LandLord = "LandLord",
  Others = "Others",
  POS = "POS",
  Retailer = "Retailer",
  Service = "Service",
  Uniform = "Uniform",
}

export enum TimeSheetType {
  Paid = "Paid",
  Unpaid = "Unpaid",
}

export enum VaccineStatus {
  FULLY_VACCINATED = "FULLY_VACCINATED",
  PARTIALLY_VACCINATED = "PARTIALLY_VACCINATED",
  UNVACCINATED = "UNVACCINATED",
}

export enum XeroLinkType {
  CreditNote = "CreditNote",
  Invoice = "Invoice",
  Transaction = "Transaction",
}

export enum XeroReportTypes {
  BankSummary = "BankSummary",
  ProfitAndLoss = "ProfitAndLoss",
}

export interface AgreeShiftsInput {
  shiftIds?: string[] | null;
}

export interface ApproveTimeOffInput {
  managerNotes?: string | null;
  id: string;
}

export interface ConfirmCashDepositInput {
  id: string;
}

export interface CreateActiveRankInput {
  userId: string;
  branchId: string;
  rankId?: string | null;
  type?: ActiveRankType | null;
  dispatchType?: DispatchType | null;
}

export interface CreateCashDepositInput {
  branchId: string;
  date: any;
  amount: number;
  balance: number;
  notes?: string | null;
}

export interface CreateCashUpInput {
  storeId: string;
  day: any;
  actualCardSales: number;
  actualCashSales: number;
  variance?: number | null;
  wastage?: number | null;
  hospo?: number | null;
  discount?: number | null;
  transactions?: number | null;
}

export interface CreateCategoryInput {
  name: string;
  isVeggie?: boolean | null;
  isFrozen?: boolean | null;
}

export interface CreateCommentInput {
  noteId?: string | null;
  body: string;
}

export interface CreateContactInput {
  supplierId: string;
  firstName: string;
  lastName?: string | null;
  email?: string | null;
  mobile?: string | null;
  role?: string | null;
  note?: string | null;
}

export interface CreateContractInput {
  storeId: string;
  supplierId: string;
  accountNumber?: string | null;
  expiryDate?: any | null;
}

export interface CreateCredentialInput {
  contractId: string;
  username: string;
  password: string;
  secret?: boolean | null;
}

export interface CreateFCPItemInput {
  form: FORM;
  frequency: FCPFrequency;
  name: string;
  storeId?: string | null;
  description?: string | null;
  isActive?: boolean | null;
}

export interface CreateFCPTaskInput {
  form: FORM;
  fromDate: any;
  dueDate: any;
  storeId?: string | null;
  itemId?: string | null;
  userId?: string | null;
  record?: string | null;
  submitted?: any | null;
}

export interface CreateFileInput {
  entityType: EntityType;
  type?: FileTypes | null;
  eTag?: string | null;
  name?: string | null;
  key?: string | null;
  url?: string | null;
  bucket?: string | null;
  mimetype?: string | null;
  entityId?: string | null;
}

export interface CreateFullHouseInput {
  name: string;
  displayName?: string | null;
  positions: string[];
  notes?: string | null;
  color?: string | null;
  days: number[];
  startTime: string;
  endTime: string;
  breakMinutes: number;
  fullHouseSetId: string;
}

export interface CreateFullHouseSetInput {
  name: string;
  branchId: string;
}

export interface CreateHCodeInput {
  name: string;
  uoM?: string | null;
  unit?: string | null;
  categoryIds: string[];
}

export interface CreateLearningModuleInput {
  title: string;
  employer?: string | null;
  employee?: string | null;
  version?: number | null;
  order?: number | null;
}

export interface CreateLicenseInput {
  type: LicenseType;
  entityType: EntityType;
  entityId: string;
  startDate: any;
  expireDate: any;
  fileIds?: string[] | null;
  notes?: string | null;
}

export interface CreateLightspeedConfigInput {
  storeId: string;
  companyId: number;
  siteId: number;
  posConfigId: string;
}

export interface CreateMenuInput {
  type: MenuType;
  name: string;
  category: string;
  price: number;
  posbossMenuId?: number | null;
  recipe?: string | null;
  cuts?: number | null;
  pieces?: number | null;
  storeIds?: string[] | null;
  portionIds?: string[] | null;
}

export interface CreateMonthlyReportInput {
  sales?: string | null;
  cogs?: string | null;
  wage?: string | null;
  wastage?: string | null;
  discount?: string | null;
  customers?: string | null;
  area?: string | null;
  strategy?: string | null;
  planning?: string | null;
  staff?: string | null;
  etc?: string | null;
  sales_hq?: string | null;
  cogs_hq?: string | null;
  wage_hq?: string | null;
  wastage_hq?: string | null;
  discount_hq?: string | null;
  customers_hq?: string | null;
  area_hq?: string | null;
  strategy_hq?: string | null;
  planning_hq?: string | null;
  staff_hq?: string | null;
  etc_hq?: string | null;
  toHQ?: string | null;
  branchId: string;
  date: string;
}

export interface CreateMonthlySummaryInput {
  branchId: string;
  date: any;
}

export interface CreateNoteInput {
  body: string;
  adminOnly?: boolean | null;
  title?: string | null;
  type?: NoteTypes | null;
  entityType?: EntityType | null;
  entityIds?: string[] | null;
  date?: any | null;
}

export interface CreateOperationConfigInput {
  storeId: string;
  type: OperationType;
  name: string;
  auto?: boolean | null;
  order?: number | null;
  active?: boolean | null;
}

export interface CreateOperationInput {
  configId: string;
  date: any;
  due: any;
  amount: number;
  sourceId?: string | null;
  paid?: number | null;
  notes?: string | null;
  credit?: boolean | null;
}

export interface CreateOrderItemInput {
  storeId: string;
  productId: string;
  section?: string | null;
  keep1?: number | null;
  keep2?: number | null;
}

export interface CreatePayRateInput {
  rate: number;
  isSalary: boolean;
  applied: any;
  note?: string | null;
  userId: string;
}

export interface CreatePayrunNoteInput {
  branchId: string;
  date: any;
  note: string;
}

export interface CreatePayrunSummaryInput {
  branchId: string;
  payrunEndingDate: any;
  newEmployees?: string | null;
  resignEmployees?: string | null;
  leaves?: string | null;
  subInEmployees?: string | null;
  subOutEmployees?: string | null;
  notes?: string | null;
  shiftsChecked?: boolean | null;
  timesheetsChecked?: boolean | null;
}

export interface CreatePollInput {
  branchIds?: string[] | null;
  title: string;
  description?: string | null;
  explanation?: string | null;
  expected: string;
  order?: number | null;
  options?: string[] | null;
}

export interface CreatePortionInput {
  menuId: string;
  portion: number;
  name?: string | null;
  price?: number | null;
  unit?: string | null;
  units?: number | null;
  section?: string | null;
  wastage?: number | null;
  productId?: string | null;
}

export interface CreatePosConfigInput {
  type: POSType;
  storeId: string;
}

export interface CreatePosReportConfigInput {
  adjustment: number;
  gst_inclusive: boolean;
  tos: string[];
  posConfigId: string;
}

export interface CreatePosbossConfigInput {
  username: string;
  password: string;
  posConfigId: string;
}

export interface CreatePositionTaskInput {
  positionId: string;
  task: string;
}

export interface CreateProductHistoryInput {
  productId: string;
  date: any;
  stock: number;
  price: number;
  applied?: boolean | null;
}

export interface CreateProductInput {
  supplierId: string;
  brand?: string | null;
  name: string;
  uoM?: string | null;
  price: number;
  hCodeId?: string | null;
  code: string;
  units?: number | null;
  stock?: number | null;
  description?: string | null;
  note?: string | null;
}

export interface CreatePublicHolidayInput {
  regions: Regions[];
  name: string;
  date: any;
}

export interface CreatePurchaseOrderItem {
  productId?: string | null;
  quantity: number;
  uoM?: string | null;
}

export interface CreatePurchaseOrderItemInput {
  storeId: string;
  productId: string;
  quantity: number;
  uoM: string;
}

export interface CreatePurchaseOrderItemsInput {
  branchId?: string | null;
  storeId?: string | null;
  purchaseOrderItems: CreatePurchaseOrderItem[];
}

export interface CreateResponseInput {
  pollId: string;
  answer: string;
}

export interface CreateSMRInput {
  branchId: string;
  position: string;
  rate: number;
  hours: number;
  status?: SMRStatus | null;
  note?: string | null;
  expireDate?: any | null;
}

export interface CreateShiftInput {
  branchId: string;
  color?: string | null;
  startDate: any;
  endDate: any;
  breakMinutes: number;
  userId?: string | null;
  displayName?: string | null;
  notes?: string | null;
}

export interface CreateStandingOrderInput {
  status?: StandingOrderStatus | null;
  storeId: string;
  productId?: string | null;
  uoM: string;
  monday: number;
  tuesday: number;
  wednesday: number;
  thursday: number;
  friday: number;
  saturday: number;
  sunday: number;
  note?: string | null;
}

export interface CreateStatementInput {
  branchId: string;
  configId: string;
  date: any;
  amount: number;
}

export interface CreateSubContractInvoicesInput {
  subContractInvoices: SubContractInvoice[];
  payrunEnding: any;
}

export interface CreateSupplierInput {
  name: string;
  type: SupplierTypes;
  address?: string[] | null;
  orderTos?: string[] | null;
  note?: string | null;
}

export interface CreateTimeOffInput {
  notes?: string | null;
  type: string;
  startDate: any;
  endDate: any;
  partDayType: PartDayTypes;
  userId?: string | null;
}

export interface CreateTimesheetInput {
  startDate: any;
  endDate?: any | null;
  breakMinutes?: number | null;
  shiftId?: string | null;
  branchId?: string | null;
  varianceMinutes?: number | null;
  varianceReason?: string | null;
}

export interface CreateUserContractInput {
  type: ContractType;
  startDate: any;
  endDate?: any | null;
  userId: string;
  branchId: string;
}

export interface CreateUserInput {
  givenName: string;
  surname: string;
  otherMails: string[];
  contractType: ContractType;
  positionIds: string[];
  payRate: number;
  contractBranchId: string;
  note?: string | null;
  branchIds: string[];
  commencementDate: any;
}

export interface CreateVisaInput {
  type: string;
  userId: string;
  contractBranchId?: string | null;
  jobTitle?: string | null;
  startDate?: any | null;
  expireDate?: any | null;
  notes?: string | null;
}

export interface CreateXeroConfigInput {
  operationConfigId: string;
  contactId: string;
  accountCode: string;
  sync_to?: boolean | null;
  sync_from?: boolean | null;
  types?: XeroLinkType[] | null;
  notes?: string | null;
  contactIds?: string[] | null;
}

export interface CreateXeroCreditNotesInput {
  Type?: string | null;
  Contact?: XeroContactType | null;
  Date?: any | null;
  LineAmountTypes?: string | null;
  LineItems?: XeroLineItemType[] | null;
  branchId: string;
}

export interface CreateXeroInvoiceInput {
  Type?: string | null;
  Contact?: XeroContactType | null;
  Date?: any | null;
  DueDate?: any | null;
  Status?: string | null;
  LineAmountTypes?: string | null;
  LineItems?: XeroLineItemType[] | null;
  InvoiceNumber?: string | null;
  Reference?: string | null;
  branchId: string;
}

export interface CreateXeroTransactionInput {
  Contact?: XeroContactType | null;
  Date?: any | null;
  LineItems: XeroLineItemType[];
  BankAccount?: XeroAccountType | null;
  Type?: string | null;
  branchId: string;
}

export interface DeleteCashDepositInput {
  id: string;
}

export interface DeleteCashUpInput {
  id: string;
}

export interface DeleteContactInput {
  id: string;
}

export interface DeleteContractInput {
  id: string;
}

export interface DeleteFCPItemInput {
  id: string;
}

export interface DeleteFCPTaskInput {
  id: string;
}

export interface DeleteFileInput {
  id: string;
}

export interface DeleteFullHouseInput {
  id: string;
}

export interface DeleteFullHouseSetInput {
  id: string;
}

export interface DeleteInput {
  id: string;
}

export interface DeleteLicenseInput {
  id: string;
}

export interface DeleteMonthlySummaryInput {
  id: string;
}

export interface DeleteNoteInput {
  id: string;
}

export interface DeleteProductInput {
  id: string;
}

export interface DeletePublicHolidayInput {
  id: string;
}

export interface DeletePurchaseOrderInput {
  id: string;
}

export interface DeletePurchaseOrderItemInput {
  id: string;
}

export interface DeleteS3FileInput {
  key: string;
  bucket: string;
}

export interface DeleteShiftInput {
  id: string;
}

export interface DeleteStandingOrderInput {
  id: string;
}

export interface DeleteSupplierInput {
  id: string;
}

export interface DeleteTimeOffInput {
  id: string;
}

export interface DeleteTimesheetInput {
  id: string;
}

export interface DeleteUserFromNotificationInput {
  id: string;
}

export interface DeleteVisaInput {
  id: string;
}

export interface DeleteXeroCreditNotesInput {
  CreditNoteID?: string | null;
  branchId: string;
}

export interface DeleteXeroInvoiceInput {
  InvoiceID?: string | null;
  InvoiceNumber?: string | null;
  branchId: string;
}

export interface DeleteXeroTransactionInput {
  BankTransactionID?: string | null;
  branchId: string;
}

export interface EditActiveRankInput {
  permissions?: Permission[] | null;
  type?: ActiveRankType | null;
  dispatchType?: DispatchType | null;
  id: string;
  rankId?: string | null;
}

export interface EditActivityInput {
  stravaId?: number | null;
  name?: string | null;
  distance?: number | null;
  moving_time?: number | null;
  elapsed_time?: number | null;
  total_elevation_gain?: number | null;
  type?: ActivityType | null;
  average_speed?: number | null;
  max_speed?: number | null;
  average_cadence?: number | null;
  average_heartrate?: number | null;
  max_heartrate?: number | null;
  start_date?: any | null;
  invalid?: boolean | null;
  id: string;
}

export interface EditApplicantInput {
  status?: ApplicationStatus | null;
  favorite?: boolean | null;
  fullName?: string | null;
  dateOfBirth?: any | null;
  email?: string | null;
  phoneNumber?: string | null;
  locations?: string[] | null;
  workingHours?: string | null;
  visaType?: string | null;
  note?: string | null;
  cv?: string | null;
  id: string;
}

export interface EditBranchInput {
  isOperating?: boolean | null;
  displayName?: string | null;
  region?: Regions | null;
  businessName?: string | null;
  branchEmail?: string | null;
  phoneNumber?: string[] | null;
  bankAccount1?: string[] | null;
  bankAccount2?: string[] | null;
  taxBankAccount?: string[] | null;
  address?: string[] | null;
  GSTNumber?: string[] | null;
  IRDNumber?: string[] | null;
  NZBN?: string | null;
  xeroTenantId?: string | null;
  roster?: string[] | null;
  director?: string | null;
  id: string;
}

export interface EditCashDepositInput {
  type?: CashDepositType | null;
  amount?: number | null;
  balance?: number | null;
  notes?: string | null;
  id: string;
}

export interface EditCashUpInput {
  day?: any | null;
  variance?: number | null;
  actualCardSales?: number | null;
  actualCashSales?: number | null;
  wastage?: number | null;
  hospo?: number | null;
  discount?: number | null;
  notes?: string | null;
  transactions?: number | null;
  id: string;
}

export interface EditCategoryInput {
  name?: string | null;
  isVeggie?: boolean | null;
  isFrozen?: boolean | null;
  id: string;
}

export interface EditCommentInput {
  id?: string | null;
  body?: string | null;
}

export interface EditContactInput {
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  mobile?: string | null;
  role?: string | null;
  note?: string | null;
  default?: boolean | null;
  id: string;
}

export interface EditContractInput {
  supplierId?: string | null;
  accountNumber?: string | null;
  expiryDate?: any | null;
  id: string;
}

export interface EditCredentialInput {
  username?: string | null;
  secret?: boolean | null;
  password?: string | null;
  id: string;
}

export interface EditFCPItemInput {
  isActive?: boolean | null;
  frequency?: FCPFrequency | null;
  name?: string | null;
  description?: string | null;
  id: string;
}

export interface EditFCPTaskInput {
  type?: FCPTaskType | null;
  userId?: string | null;
  record?: string | null;
  submitted?: any | null;
  id: string;
}

export interface EditFileInput {
  type?: FileTypes | null;
  name?: string | null;
  id: string;
}

export interface EditFullHouseInput {
  id: string;
  name?: string | null;
  displayName?: string | null;
  positions?: string[] | null;
  notes?: string | null;
  color?: string | null;
  days?: number[] | null;
  startTime?: string | null;
  endTime?: string | null;
  breakMinutes?: number | null;
  fullHouseSetid?: string | null;
}

export interface EditFullHouseSetInput {
  name?: string | null;
  active?: boolean | null;
  id: string;
}

export interface EditHCodeInput {
  name?: string | null;
  uoM?: string | null;
  unit?: string | null;
  categoryIds?: string[] | null;
  id: string;
}

export interface EditLearningModuleInput {
  order?: number | null;
  title?: string | null;
  employer?: string | null;
  employee?: string | null;
  version?: number | null;
}

export interface EditLicenseInput {
  type?: LicenseType | null;
  startDate?: any | null;
  expireDate?: any | null;
  notes?: string | null;
  id: string;
}

export interface EditLightspeedConfigInput {
  companyId?: number | null;
  siteId?: number | null;
}

export interface EditLightspeedProductInput {
  name?: string | null;
  description?: string | null;
  unit_price?: number | null;
}

export interface EditMenuInput {
  type?: MenuType | null;
  name?: string | null;
  category?: string | null;
  recipe?: string | null;
  price?: number | null;
  storeIds?: string[] | null;
  portionIds?: string[] | null;
  cuts?: number | null;
  pieces?: number | null;
  id: string;
  posbossMenuId?: number | null;
}

export interface EditMonthlyReportInput {
  sales?: string | null;
  cogs?: string | null;
  wage?: string | null;
  wastage?: string | null;
  discount?: string | null;
  customers?: string | null;
  area?: string | null;
  strategy?: string | null;
  planning?: string | null;
  staff?: string | null;
  etc?: string | null;
  sales_hq?: string | null;
  cogs_hq?: string | null;
  wage_hq?: string | null;
  wastage_hq?: string | null;
  discount_hq?: string | null;
  customers_hq?: string | null;
  area_hq?: string | null;
  strategy_hq?: string | null;
  planning_hq?: string | null;
  staff_hq?: string | null;
  etc_hq?: string | null;
  toHQ?: string | null;
  id: string;
}

export interface EditMonthlySummaryInput {
  sales_total?: number | null;
  sales_card?: number | null;
  sales_subcontract?: number | null;
  sales_other?: number | null;
  sales_cash?: number | null;
  sales_delivery?: number | null;
  transactions?: number | null;
  atv?: number | null;
  cogs_total?: number | null;
  expenses_total?: number | null;
  labour_wage?: number | null;
  labour_subcontract?: number | null;
  labour_total?: number | null;
  gst?: number | null;
  income_tax?: number | null;
  hq_payment?: number | null;
  adjustment?: number | null;
  banking?: number | null;
  balance?: number | null;
  confirmed_balance?: number | null;
  wastage?: number | null;
  variance?: number | null;
  discount?: number | null;
  hospo?: number | null;
  days?: number | null;
  hours?: number | null;
  expenses_gas?: number | null;
  expenses_power?: number | null;
  expenses_internet?: number | null;
  expenses_lease_premise?: number | null;
  id: string;
}

export interface EditNoteInput {
  type?: NoteTypes | null;
  body?: string | null;
  title?: string | null;
  adminOnly?: boolean | null;
  id: string;
  entityType?: EntityType | null;
  entityIds?: string[] | null;
}

export interface EditOperationConfigInput {
  storeId?: string | null;
  type?: OperationType | null;
  name?: string | null;
  auto?: boolean | null;
  order?: number | null;
  active?: boolean | null;
  id: string;
}

export interface EditOperationInput {
  date?: any | null;
  due?: any | null;
  amount?: number | null;
  paid?: number | null;
  credit?: boolean | null;
  notes?: string | null;
  id: string;
}

export interface EditOrderItemInput {
  active?: boolean | null;
  section?: string | null;
  keep?: number | null;
  keep2?: number | null;
  productId?: string | null;
  id: string;
}

export interface EditOrderItemReviewInput {
  note?: string | null;
  isApproved?: boolean | null;
  id: string;
}

export interface EditPayRateInput {
  rate?: number | null;
  isSalary?: boolean | null;
  applied?: any | null;
  note?: string | null;
  id: string;
}

export interface EditPayrunNoteInput {
  date?: any | null;
  note?: string | null;
  id: string;
}

export interface EditPayrunSummaryInput {
  id?: string | null;
  branchId?: string | null;
  payrunEndingDate?: any | null;
  newEmployees?: string | null;
  resignEmployees?: string | null;
  leaves?: string | null;
  subInEmployees?: string | null;
  subOutEmployees?: string | null;
  notes?: string | null;
  shiftsChecked?: boolean | null;
  timesheetsChecked?: boolean | null;
}

export interface EditPollInput {
  branchIds?: string[] | null;
  title?: string | null;
  description?: string | null;
  explanation?: string | null;
  options?: string[] | null;
  expected?: string | null;
  order?: number | null;
  id: string;
}

export interface EditPortionInput {
  section?: string | null;
  portion?: number | null;
  wastage?: number | null;
  productId?: string | null;
  name?: string | null;
  price?: number | null;
  unit?: string | null;
  units?: number | null;
  id: string;
}

export interface EditPosConfigInput {
  type?: POSType | null;
}

export interface EditPosReportConfigInput {
  adjustment?: number | null;
  gst_inclusive?: boolean | null;
  tos?: string[] | null;
}

export interface EditPosbossConfigInput {
  username?: string | null;
  password?: string | null;
}

export interface EditPositionInput {
  name?: string | null;
  id: string;
}

export interface EditPositionTaskInput {
  task?: string | null;
  order?: number | null;
  id: string;
}

export interface EditProductHistoryInput {
  date?: any | null;
  stock?: number | null;
  price?: number | null;
  id: string;
}

export interface EditProductInput {
  status?: ProductStatusTypes | null;
  hCodeId?: string | null;
  supplierId?: string | null;
  brand?: string | null;
  name?: string | null;
  description?: string | null;
  note?: string | null;
  code?: string | null;
  uoM?: string | null;
  units?: number | null;
  stock?: number | null;
  price?: number | null;
  imageUrl?: string | null;
  id: string;
}

export interface EditPublicHolidayInput {
  regions?: Regions[] | null;
  name?: string | null;
  date?: any | null;
  id: string;
}

export interface EditPurchaseOrderInput {
  fullfilled?: any | null;
  delivery?: any | null;
  note?: string | null;
  id: string;
}

export interface EditPurchaseOrderItemInput {
  quantity?: number | null;
  note?: string | null;
  id: string;
}

export interface EditRankInput {
  name?: string | null;
  description?: string | null;
  permissions?: Permission[] | null;
  grade?: number | null;
  id: string;
}

export interface EditSMRInput {
  status?: SMRStatus | null;
  note?: string | null;
  expireDate?: any | null;
  id: string;
}

export interface EditShiftInput {
  userId?: string | null;
  displayName?: string | null;
  notes?: string | null;
  color?: string | null;
  startDate?: any | null;
  endDate?: any | null;
  breakMinutes?: number | null;
  id: string;
}

export interface EditStandingOrderInput {
  status?: StandingOrderStatus | null;
  productId?: string | null;
  uoM?: string | null;
  monday?: number | null;
  tuesday?: number | null;
  wednesday?: number | null;
  thursday?: number | null;
  friday?: number | null;
  saturday?: number | null;
  sunday?: number | null;
  note?: string | null;
  id: string;
}

export interface EditStoreInput {
  displayName?: string | null;
  address?: string | null;
  productIds?: string[] | null;
  startTimeMonday?: string | null;
  endTimeMonday?: string | null;
  startTimeTuesday?: string | null;
  endTimeTuesday?: string | null;
  startTimeWednesday?: string | null;
  endTimeWednesday?: string | null;
  startTimeThursday?: string | null;
  endTimeThursday?: string | null;
  startTimeFriday?: string | null;
  endTimeFriday?: string | null;
  startTimeSaturday?: string | null;
  endTimeSaturday?: string | null;
  startTimeSunday?: string | null;
  endTimeSunday?: string | null;
  longitude?: number | null;
  latitude?: number | null;
  id: string;
}

export interface EditSupplierInput {
  name?: string | null;
  type?: SupplierTypes | null;
  address?: string[] | null;
  orderTos?: string[] | null;
  note?: string | null;
  id: string;
}

export interface EditTimeOffInput {
  userId?: string | null;
  notes?: string | null;
  type?: string | null;
  startDate?: any | null;
  endDate?: any | null;
  partDayType?: PartDayTypes | null;
  id: string;
}

export interface EditTimesheetInput {
  startDate?: any | null;
  endDate?: any | null;
  breakMinutes?: number | null;
  varianceMinutes?: number | null;
  varianceReason?: string | null;
  varianceConfirm?: string | null;
  id: string;
}

export interface EditUserContractInput {
  type?: ContractType | null;
  startDate?: any | null;
  endDate?: any | null;
  id: string;
  branchId?: string | null;
}

export interface EditUserInput {
  id?: string | null;
  userPrincipalName?: string | null;
  givenName?: string | null;
  middleName?: string | null;
  surname?: string | null;
  titleName?: string | null;
  displayName?: string | null;
  nickname?: string | null;
  otherMails?: string[] | null;
  mobilePhone?: string | null;
  irdNumber?: string[] | null;
  bankAccountNumber?: string[] | null;
  address?: string | null;
  birthday?: any | null;
  minHours?: number | null;
  maxHours?: number | null;
  vaccineStatus?: VaccineStatus | null;
  positionIds?: string[] | null;
}

export interface EditVendProductInput {
  id: string;
  count: number;
  price: number;
}

export interface EditVisaInput {
  id?: string | null;
  type?: string | null;
  userId?: string | null;
  contractBranchId?: string | null;
  jobTitle?: string | null;
  startDate?: any | null;
  expireDate?: any | null;
  notes?: string | null;
}

export interface EditXeroAssetInput {
  assetId: string;
  assetName: string;
  assetNumber: string;
  assetStatus: string;
  purchaseDate?: string | null;
  purchasePrice?: number | null;
  disposalPrice?: number | null;
  accountingBookValue?: number | null;
  serialNumber?: string | null;
  warrantyExpiryDate?: string | null;
  description?: string | null;
}

export interface EditXeroConfigInput {
  operationConfigId?: string | null;
  contactId?: string | null;
  accountCode?: string | null;
  sync_to?: boolean | null;
  sync_from?: boolean | null;
  types?: XeroLinkType[] | null;
  notes?: string | null;
  id: string;
  contactIds?: string[] | null;
}

export interface FetchCashUpInput {
  storeId: string;
  date: any;
}

export interface FetchVendProductsInput {
  supplierName: string;
}

export interface FetchVendProductsNotSoldInput {
  from: string;
}

export interface GetActiveRanksInput {
  userIds?: string[] | null;
  branchIds?: string[] | null;
  rankIds?: string[] | null;
}

export interface GetActivitiesInput {
  userId?: string | null;
  startDate?: any | null;
  endDate?: any | null;
  types?: ActivityType[] | null;
}

export interface GetApplicantsInput {
  first?: number | null;
  offset?: number | null;
  statuses?: ApplicationStatus[] | null;
  favorite?: boolean | null;
}

export interface GetBranchInput {
  id: string;
}

export interface GetBranchesInput {
  first?: number | null;
  offset?: number | null;
}

export interface GetCashBalanceInput {
  branchId: string;
  date: any;
}

export interface GetCashDepositsInput {
  branchId?: string | null;
  date?: any | null;
  first?: number | null;
  offset?: number | null;
}

export interface GetCashUpsInput {
  storeId?: string | null;
  branchId?: string | null;
  startDate: any;
  endDate: any;
}

export interface GetContactsInput {
  supplierId?: string | null;
}

export interface GetContractsInput {
  storeId?: string | null;
  supplierId?: string | null;
}

export interface GetCredentialsInput {
  contractId?: string | null;
  supplierId?: string | null;
  storeId?: string | null;
  branchId?: string | null;
}

export interface GetFCPItemsInput {
  forms?: FORM[] | null;
  storeIds?: string[] | null;
  first?: number | null;
  offset?: number | null;
}

export interface GetFCPTaskCountsInput {
  forms?: FORM[] | null;
  storeIds?: string[] | null;
}

export interface GetFCPTasksInput {
  forms?: FORM[] | null;
  itemIds?: string[] | null;
  storeIds?: string[] | null;
  userIds?: string[] | null;
  first?: number | null;
  offset?: number | null;
  startDate?: string | null;
  endDate?: string | null;
  types?: FCPTaskType[] | null;
}

export interface GetFilesInput {
  userId?: string | null;
  entityId?: string | null;
  entityType?: string | null;
  first?: number | null;
  offset?: number | null;
  types?: FileTypes[] | null;
}

export interface GetFullHouseSetsInput {
  branchId: string;
}

export interface GetFullHousesInput {
  fullHouseSetId: string;
}

export interface GetHCodeInput {
  id: string;
}

export interface GetHCodesInput {
  categoryId?: string | null;
}

export interface GetJobDescriptionsInput {
  userId?: string | null;
  userIds?: string[] | null;
}

export interface GetLearningCertificatesInput {
  userId?: string | null;
  userIds?: string[] | null;
  branchId?: string | null;
  branchIds?: string[] | null;
  learningModuleId?: string | null;
  learningModuleIds?: string[] | null;
}

export interface GetLeaveRequestInfoInput {
  userId: string;
  type: string;
  partDayType: PartDayTypes;
  start_date: string;
  end_date: string;
}

export interface GetLicenseInput {
  id: string;
}

export interface GetLicensesInput {
  entityType?: EntityType | null;
  entityIds?: string[] | null;
  types?: LicenseType[] | null;
  first?: number | null;
  offset?: number | null;
}

export interface GetLogsInput {
  first?: number | null;
  offset?: number | null;
  entityType?: EntityType | null;
  entityId?: string | null;
  types?: LogTypes[] | null;
}

export interface GetMenusInput {
  first?: number | null;
  offset?: number | null;
  storeIds?: string[] | null;
}

export interface GetMonthlyReportInput {
  branchId: string;
  date: string;
}

export interface GetMonthlySummariesInput {
  branchIds?: string[] | null;
  startDate?: any | null;
  endDate?: any | null;
}

export interface GetNoteInput {
  id: string;
}

export interface GetNotesInput {
  first?: number | null;
  offset?: number | null;
  entityId?: string | null;
  types?: NoteTypes[] | null;
  entityType: EntityType;
  entityIds?: string[] | null;
}

export interface GetOperationConfigsInput {
  branchIds?: string[] | null;
  storeIds?: string[] | null;
}

export interface GetOperationsInput {
  types?: OperationType[] | null;
  configIds?: string[] | null;
  branchIds?: string[] | null;
  storeIds?: string[] | null;
  supplierIds?: string[] | null;
  startDate: any;
  endDate: any;
}

export interface GetOrderItemReviewsInput {
  productId?: string | null;
  first?: number | null;
  offset?: number | null;
  includeApproved?: boolean | null;
}

export interface GetOrderItemsInput {
  storeId?: string | null;
  branchId?: string | null;
  hCodeId?: string | null;
  hCodeName?: string | null;
}

export interface GetOtherwiseWorkingDaysInput {
  branchId: string;
  start_date?: string | null;
  end_date?: string | null;
}

export interface GetPayRatesInput {
  userIds?: string[] | null;
  branchIds?: string[] | null;
  contractBranchIds?: string[] | null;
  startDate?: any | null;
  endDate?: any | null;
}

export interface GetPayrunNotesInput {
  branchId?: string | null;
  startDate: any;
  endDate: any;
  first?: number | null;
  offset?: number | null;
}

export interface GetPayrunSummariesInput {
  branchId?: string | null;
  payrunEndingDate?: any | null;
  first?: number | null;
  offset?: number | null;
}

export interface GetPollsInput {
  all?: boolean | null;
}

export interface GetPortionsInput {
  first?: number | null;
  offset?: number | null;
  menuIds?: string[] | null;
}

export interface GetPosbossMenusInput {
  storeId: string;
}

export interface GetProductHistoriesInput {
  productId?: string | null;
  supplierId?: string | null;
  trackPrices?: boolean | null;
  trackStocks?: boolean | null;
  first?: number | null;
  offset?: number | null;
}

export interface GetProductInput {
  id: string;
}

export interface GetProductsInput {
  storeId?: string | null;
  supplierId?: string | null;
  search?: string | null;
  categoryId?: string | null;
  hCodeId?: string | null;
  showAll?: boolean | null;
  includeInactive?: boolean | null;
}

export interface GetPublicHolidaysInput {
  regions?: Regions[] | null;
  startDate: any;
  endDate: any;
}

export interface GetPurchaseOrderItemsInput {
  purchaseOrderId?: string | null;
  productId?: string | null;
  onlyWithNote?: boolean | null;
  first?: number | null;
  offset?: number | null;
  startDate?: any | null;
  endDate?: any | null;
}

export interface GetPurchaseOrderItemsSummaryInput {
  startDate: any;
  endDate: any;
  supplierId?: string | null;
  storeId?: string | null;
}

export interface GetPurchaseOrdersDetailsInput {
  startDate: any;
  endDate: any;
  hCodeId: string;
}

export interface GetPurchaseOrdersInput {
  storeId?: string | null;
  first?: number | null;
  offset?: number | null;
  fullfilled?: boolean | null;
}

export interface GetResponsesInput {
  userIds?: string[] | null;
  pollIds?: string[] | null;
  first?: number | null;
  offset?: number | null;
}

export interface GetSMRsInput {
  branchIds: string[];
  first?: number | null;
  offset?: number | null;
}

export interface GetShiftsInput {
  branchId?: string | null;
  contractBranchId?: string | null;
  userId?: string | null;
  startDate: any;
  endDate: any;
  first?: number | null;
  offset?: number | null;
  includeUnpublished?: boolean | null;
  includeOtherBranches?: boolean | null;
}

export interface GetStandingOrdersInput {
  storeId?: string | null;
}

export interface GetStoreInput {
  id: string;
}

export interface GetStoresInput {
  branchId?: string | null;
  first?: number | null;
  offset?: number | null;
}

export interface GetSuppliersInput {
  storeId?: string | null;
  type?: SupplierTypes | null;
}

export interface GetTimeOffsInput {
  contractBranchId?: string | null;
  branchId?: string | null;
  userId?: string | null;
  startDate: any;
  endDate: any;
  first?: number | null;
  offset?: number | null;
  includeUnapproved?: boolean | null;
}

export interface GetTimesheetsInput {
  branchId?: string | null;
  userId?: string | null;
  startDate: any;
  endDate: any;
  subContractOnly?: boolean | null;
  first?: number | null;
  offset?: number | null;
}

export interface GetUserContractsInput {
  userId?: string | null;
  branchId?: string | null;
  branchIds?: string[] | null;
  userIds?: string[] | null;
  first?: number | null;
  offset?: number | null;
}

export interface GetUserInput {
  id: string;
}

export interface GetUsersInput {
  contractBranchId?: string | null;
  branchIds?: string[] | null;
  userIds?: string[] | null;
  first?: number | null;
  offset?: number | null;
  isEmployed?: boolean | null;
  contractStartDate?: any | null;
  contractEndDate?: any | null;
}

export interface GetVisaInput {
  id: string;
}

export interface GetVisasInput {
  userId?: string | null;
  first?: number | null;
  offset?: number | null;
}

export interface GetXeroAccountsInput {
  branchId: string;
}

export interface GetXeroConfigsInput {
  branchIds?: string[] | null;
  storeIds?: string[] | null;
  name?: string | null;
  auto?: boolean | null;
}

export interface GetXeroContactsInput {
  branchId: string;
  name?: string | null;
}

export interface GetXeroCreditNotesInput {
  branchId: string;
  startDate: any;
  endDate: any;
  Type?: string | null;
}

export interface GetXeroInvoiceInput {
  branchId: string;
  invoiceId: string;
}

export interface GetXeroInvoicesInput {
  branchId: string;
  startDate: any;
  endDate: any;
  Type?: string | null;
  ContactIds?: string[] | null;
}

export interface GetXeroReportInput {
  id: string;
  type: XeroReportTypes;
  startDate: any;
  endDate: any;
  paymentsOnly?: boolean | null;
}

export interface GetXeroTransactionsInput {
  branchId: string;
  startDate: any;
  endDate: any;
  BankAccountCode?: string | null;
  UnitAmount?: number | null;
  Type?: string | null;
  ContactId?: string | null;
}

export interface MergeProductInput {
  id: string;
  to: string;
}

export interface PlacePurchaseOrderInput {
  purchaseOrderId: string;
}

export interface PublishShiftsInput {
  shiftIds?: string[] | null;
}

export interface QueryProductInput {
  supplierId: string;
  code: string;
}

export interface SubContractInvoice {
  invoiceBranchId: string;
  billBranchId: string;
  amount: number;
}

export interface SyncInput {
  branchId: string;
  startDate: any;
  endDate: any;
}

export interface XeroAccountType {
  AccountID?: string | null;
  Code: string;
  Name?: string | null;
  Type?: string | null;
  BankAccountNumber?: string | null;
  Status?: string | null;
  Description?: string | null;
  BankAccountType?: string | null;
  CurrencyCode?: string | null;
}

export interface XeroContactType {
  ContactID: string;
  ContactStatus?: string | null;
  Name?: string | null;
  FirstName?: string | null;
  LastName?: string | null;
  EmailAddress?: string | null;
  BankAccountDetails?: string | null;
}

export interface XeroLineItemType {
  Description?: string | null;
  UnitAmount: number;
  TaxType?: string | null;
  TaxAmount?: string | null;
  LineAmount?: string | null;
  AccountCode: string;
  Quantity?: number | null;
  LineItemID?: string | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
